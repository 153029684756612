<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
          class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>Prescription Requests</h3>
        </div>
        <div class="row mt-3 mb-3" v-if="message">
          <div class="col-12">
            <small class="text-danger" v-if="isError">{{ message }}</small>
            <small class="text-success" v-else>{{ message }}</small>
          </div>
        </div>
        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>User</th>
                  <th>Notes</th>
                  <th>File</th>
                  <th>Status</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="prescription in prescriptions"
                  :key="prescription.id"
                >
                  <td>
                    <router-link
                      :to="{
                        name: 'merchant_prescription_order_detail',
                        params: { id: prescription.id },
                      }"
                      >{{ prescription.id }}</router-link
                    >
                  </td>
                  <td>{{ prescription.user.name }}</td>
                  <td>{{ prescription.notes }}</td>
                  <td>
                    <a
                      ><img
                        :src="prescription.file"
                        alt="Prescription Image"
                      />View</a
                    >
                  </td>
                  <td>
                    <span
                      v-if="prescription.status.name === 'pending'"
                      class="text-warning"
                      >{{ prescription.status.label }}
                    </span>
                    <span
                      v-if="prescription.status.name === 'paid'"
                      class="text-success"
                      >{{ prescription.status.label }}
                    </span>
                    <span
                      v-if="prescription.status.name === 'approved'"
                      class="text-danger"
                      >{{ prescription.status.label }}
                    </span>
                  </td>
                  <td class="text-center">
                    <router-link
                      target="_blank"
                      :to="{ name: 'merchant_create_prescription_order' }"
                      ><i class="icon-cart-add"></i> Create Order</router-link
                    >
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="(!meta || meta.total === 0) && !isLoading">
                  <td colspan="6" class="text-center m-4 text-danger p-4">
                    No prescriptions requests available
                  </td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="6" class="text-center m-4 text-danger">
                    Loading requests...
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PrescriptionRequestList",
  data() {
    return {
      prescriptions: [],
      meta: null,
      isLoading: false,
      isError: false,
      message: null,
    };
  },
  mounted() {
    this.getPrescriptionOrders();
  },
  methods: {
    getPrescriptionOrders() {
      this.isLoading = true;
      axios
        .get("medicalprescriptions")
        .then((response) => {
          this.prescriptions = response.data.data;
          this.meta = response.data.meta;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    deleteOrder(id) {
      if (confirm("Are you sure you want to delete this prescription order")) {
        this.isLoading = true;
        axios
          .delete("medical_prescriptions/" + id)
          .then((response) => {
            this.getPrescriptionOrders();
            this.message = "Prescription order deleted successfully";
          })
          .catch((error) => {
            this.isLoading = false;
            this.isError = true;
            this.message = "An error occurred deleting the prescription order";
          });
      }
    },
  },
};
</script>

<style scoped></style>
